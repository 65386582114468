import rest_api from "@/rest_api";
import graphql_general from "@/graphql/general";
import store from "@/store";


export default {
    login(username, password) {
        //login to get token
        return rest_api.user
            .login(username, password)
            .then(() => rest_api.user.get())
            .then((user_data) => {
                if (user_data) {
                    return this.maintain_user_data(user_data);
                } else {
                    throw "Could not get user data.";
                }
            })
            .then((user_data) => {
                if ("id_b64" in user_data){
                    return this.maintain_account_data(user_data);
                }
                else{
                    throw "Invalid user data."
                }
               
            });

        // update user information
    },
    maintain_user_data(user_data) {
        // Maintains all the user session data in the store.
        return graphql_general
            .get_user_roles(user_data.id_b64)
            .then((res) => {
                user_data.roles = res;
            })
            .then(() => {
                store.store.commit("setUser", user_data);
                return user_data;
            })
            .catch(() => {
                user_data.roles = [];
                store.store.commit("setUser", user_data);
                return user_data;
            });
    },
    maintain_account_data(user_data) {
        return graphql_general.get_account(user_data.id_b64).then((account) => {
            store.store.commit("setAccount", account);
            return account;
        });
    },

    maintain_session_data(){
        this.maintain_account_data(store.store.getters.getUser)
        this.maintain_user_data(store.store.getters.getUser)
    }
};
