import state from "./state";
import mutations from "./mutations";
import getters from "./getters";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: state,
    mutations: mutations,
    getters: getters,
});

export default {
    store: store,
};
